<template>
	<div>
		<div class="xianm">
			<div v-for="(item,index) in threeLevel" :key="index" v-if="index < 3" :class="columnIndex == index + 1 ? 'name name2':'name'" @click="column(index + 1,item.id,item)">{{item.name}}</div>

		</div>
		
		<div class="xiaoxian">
			<div class="title" @click="getsports('','')" :style="{'color':sportsindex == '' ? '#3C75C2':'#000' }">全部</div>
			<div class="name" v-for="(item,index) in sportsList" :key="index" @click="getsports(index + 1,item)"
				:style="{'color':sportsindex == index + 1 ? '#3C75C2':'#000' }">
				{{item.name}}
			</div>
		</div>
		
		<div class="articlelist" v-for="(item,index) in date" :key="index"
			v-if="template == 'picture_column' || template == 'picture_list' || template == 'picture'"
			@click="jump(item)">
			<div class="imgbox">
				<div class="title-img">{{item.title}}</div>
				<img :src="item.imgUrl" alt="" v-if="item.imgUrl">
				<img src="../../assets/image/placeholder.png" alt="" v-else>
			</div>
			<div class="date">{{item.createTime | format}}</div>
			<div class="xian"></div>
		</div>
		
		<div v-for="(item,index) in date" :key="index" v-if="template == 'date_list'" @click="jump(item)">
			<div style="height: 72PX;padding: 20PX;">
				<div class="news-date">
					<b>{{item.publishTime.split(" ")[0].split("-")[2]}}</b>
					<p>{{item.publishTime.split(" ")[0].split("-")[0]}}-{{item.publishTime.split(" ")[0].split("-")[1]}}
					</p>
				</div>
				<h2>
					{{item.title}}
				</h2>
			</div>
			<div class="xian" style="margin: 30PX 0 0 0; "></div>
		</div>
		
		<div class="articlelist" v-for="(item,index) in date" :key="index" v-if="template == 'common_list'"
			@click="jump(item)">
			<div class="title">{{item.title}}</div>
			<div class="date">{{item.createTime | format}}</div>
			<div class="xian"></div>
		</div>
		
		<div class="articlelist" v-for="(item,index) in date" :key="index" v-if="template == ''" @click="jump(item)">
			<div class="title">{{item.title}}</div>
			<div class="date">{{item.createTime | format}}</div>
			<div class="xian"></div>
		</div>
		
		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :type="2" :go="12" :pageSize="12" :pageNo="pageNum"></pagination>
		</div>
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import Meta from 'vue-meta'
	Vue.use(Meta)
	export default {
		name: '',
		props: {
			id: {
				type: [Number, String],
				default: ''
			},
			pid: {
				type: [Number, String],
				default: ''
			},
		},
		components: {},
		data() {
			return {
				id2: '',
				pageNum: 1,
				pageSize: 20,
				total: 0,
				date: [],
				template: '',
				columntitle:'',
				title: '',
				sportsList: [],
				sportsindex: '',
				columnIndex: 1,
				threeLevel: []
			}
		},
		filters: {
			format(x) {
				if(x != null && x != ''){
					return x.split(" ")[0]
				}
			}
		},
		created() {
			this.findAllColumn()
		},
		methods: {
			getRefereeSports(id) {
				this.$api.getRefereeSports({
					dictId: id
				}).then(res => {
					this.sportsList = res.data.data.result
				})
			},
			column(e, id,item) {
				this.columnIndex = e
				this.sportsindex = 0
				this.findLabelList(id)
				this.template = item.template
			},
			findAllColumn() {
				let data = {
					id: this.id,
					pid: this.pid
				}
				this.$api.findAllColumn(data).then(res => {
					// this.columntitle = res.data.data.list[0].name
					// this.title = res.data.data.pid.name
					document.title = res.data.data.pid.name + '-北京市体育竞赛管理和国际交流中心'
					this.$meta().refresh()
					
					this.$getwxsdk(res.data.data.pid.name + '-北京市体育竞赛管理和国际交流中心')
					
					for (let i in res.data.data.list[0].children) {
						if (res.data.data.list[0].children[i].id == this.id) {
							this.threeLevel = res.data.data.list[0].children[i].children
							this.id2 = res.data.data.list[0].children[i].children[0].id
							this.template =  res.data.data.list[0].children[i].children[0].template
						}
					}
					this.findLabelList(this.id2)
				})
			},
			currentchange(val) {
				this.pageNum = val
				this.getArticleListById(this.id2,'')
			},
			getArticleListById(id, labelId) {
				let data = {
					id: id,
					pageNum: this.pageNum,
					pageSize: this.pageSize,
					labelId: labelId || null,
				}
				this.$api.getArticleListById(data).then(res => {
					this.total = res.data.data.total
					this.date = res.data.data.list
					this.date.forEach(item=>{
						item.title= item.title.replace(/<br>/g, ' ')
					})
				})
			},
			jump(id) {
				let routeData = this.$router.resolve({
					path: '/articleDetails',
					query: {
						id: id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
			getText(str) {
				let hasChineseFontFamily = /font-family:\s*[^;]*[\u4E00-\u9FFF]+/g.test(str)
				if (hasChineseFontFamily) {
				  str = str.replace(/font-family:\s*[^;]*[\u4E00-\u9FFF]+/g, "font-family: ''");
				}
				let res = str.replace(/[^\u4e00-\u9fa5]/gi, "").replace("微软雅黑", "")
				if (res.length > 80) {
					res = res.slice(0, 80) + '...'
				}
				return res
			},
			findLabelList(id,item) {
				let data = {
					columnIds: [id]
				}
				this.$api.findLabelList(data).then(res => {
					this.sportsList = res.data.data
				})
				this.pageNum = 1
				this.getArticleListById(id, '')
				this.id2 = id
			},
			getsports(index, item) {
				this.sportsindex = index
				this.pageNum = 1
				this.getArticleListById(this.id2, item.id || '')
			},
			jump(item) {
				this.$router.push({
					path: '/h5/H5articledetails',
					query: {
						id: item.id,
						refresh: new Date().getTime(),
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.xianm {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 14PX 22PX  0;
		background: #F0F7FF;
		border: 1PX solid #4497F8;
		border-radius: 9PX;
	
		.name {
			display: flex;
			font-size: 13PX;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #1E71EF;
			padding: 10PX;
		}
	
		.name:nth-child(3n) {
			margin-right: 0;
		}
	
		.name2 {
			color: #FFFFFF;
			background: #0A6DF3;
			border-radius: 8PX;
			height: 100%;
			padding: 10PX;
		}
	}
	.xiaoxian {
		background: #F0F7FF;
		border-radius: 8PX;
		margin: 18PX 22PX  0;
		display: flex;
		flex-wrap: wrap;
		padding: 13PX 17PX 0 17PX;
	
		.name {
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			margin-right: 15PX;
			margin-bottom: 13PX;
		}
		.title{
			margin-right: 15PX;
		}
	}
	
	.articlelist {
	
		.title {
			height: 36PX;
			font-size: 14PX;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			margin: 17PX 22PX 0 22PX;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	
		.date {
			font-size: 11PX;
			font-family: Source Han Sans CN;
			font-weight: 400;
			margin-top: 5PX;
			margin-right: 23PX;
			text-align: right;
			margin-bottom: 12PX;
		}
	
	
		.imgbox {
			margin: 17PX 22PX 0 22PX;
			display: flex;
			justify-content: space-between;
	
			img {
				width: 114PX;
				height: 70PX;
				border-radius: 10PX;
				flex-shrink: 0;
			}
	
			.title-img {
				height: 73PX;
				font-size: 14PX;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 4;
				line-clamp: 4;
				-webkit-box-orient: vertical;
				margin-right: 10PX;
			}
		}
	}
	
	.news-date {
		width: 72PX;
		height: 72PX;
		border: 1PX solid #bfd4e9;
		float: left;
		margin-right: 20PX;
		font-family: arial;
		color: #6198e2;
		background: #fff;
		text-align: center;
		transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
	}
	
	.news-date b {
		height: 43PX;
		line-height: 42PX;
		font-size: 26PX;
	}
	
	.news-date p {
		font-size: 14PX;
		margin: 0 5PX;
		height: 26PX;
		line-height: 26PX;
		color: #6198e2;
		background: #fff;
		border-top: 1PX solid #dfe7f3;
		transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
	}
	
	h2 {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		line-clamp: 4;
		-webkit-box-orient: vertical;
	}
	
	.xian {
		width: 100%;
		height: 4PX;
		background: #F4F8FB;
	}
	
	.paging {
		margin-bottom: 28PX;
		margin-left: 28PX;
		display: flex;
		align-items: center;
	}
	
	.paging span {
		margin-right: 5PX;
	}
</style>